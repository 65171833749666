<template>
    <section class="section">
        <div class="container">
            <div class="content is-medium">
                <h2>Impressum</h2>
                <p><strong>Kontakt-Adresse</strong></p>
                <p>Swiss Domino Effect GmbH<br />Tohuebstrasse 2<br />8580 Hefenhofen<br />Schweiz</p>
                <p>E-Mail:<br />info@swissdominoeffect.com</p><br /><br />
                <p><strong>Vertretungsberechtigte Person(en)</strong></p>Jonas Oswald, Vorsitzender der
                Gesch&auml;ftsf&uuml;hrung<br />Daniel Huwiler, Gesch&auml;ftsf&uuml;hrer<br /><br />
                <p><strong>Handelsregister-Eintrag</strong></p>
                <p>Eingetragener Firmenname: Swiss Domino Effect GmbH<br />Handelsregister Nr: CHE-248.195.825</p>
                <br /><br />
                <p><strong>Haftungsausschluss</strong></p>Der Autor &uuml;bernimmt keinerlei Gew&auml;hr hinsichtlich
                der inhaltlichen Richtigkeit, Genauigkeit, Aktualit&auml;t, Zuverl&auml;ssigkeit und
                Vollst&auml;ndigkeit der Informationen.</p>
                <p>Haftungsanspr&uuml;che gegen den Autor wegen Sch&auml;den materieller oder immaterieller Art, welche
                    aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der ver&ouml;ffentlichten Informationen, durch
                    Missbrauch der Verbindung oder durch technische St&ouml;rungen entstanden sind, werden
                    ausgeschlossen.</p>
                <p>Alle Angebote sind unverbindlich. Der Autor beh&auml;lt es sich ausdr&uuml;cklich vor, Teile der
                    Seiten oder das gesamte Angebot ohne besondere Ank&uuml;ndigung zu ver&auml;ndern, zu erg&auml;nzen,
                    zu l&ouml;schen oder die Ver&ouml;ffentlichung zeitweise oder endg&uuml;ltig einzustellen.</p>
                <br /><br />
                <p><strong>Haftungsausschluss f&uuml;r Links</strong></p>
                <p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird
                    jegliche Verantwortung f&uuml;r solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher
                    Webseiten erfolgen auf eigene Gefahr des jeweiligen Nutzers.</p><br /><br />
                <p><strong>Urheberrechte</strong></p>
                <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser
                    Website, geh&ouml;ren ausschliesslich <strong>der Firma Swiss Domino Effect GmbH</strong> oder den
                    speziell genannten Rechteinhabern. F&uuml;r die Reproduktion jeglicher Elemente ist die schriftliche
                    Zustimmung des Urheberrechtstr&auml;gers im Voraus einzuholen.</p><br /><br />
                <!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall geahndet wird.-->Quelle:
                <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">SwissAnwalt</a>
                <!--Bitte beachten Sie die AGB von SwissAnwalt betreffend allf&auml;llig anfallenden Kosten bei Weglassen der Quelle!-->
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        metaInfo: {
            title: 'Impressum'
        },
    }
</script>